import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { history } from '../store'
import { push } from 'react-router-redux'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next"
import { fetchLockers, postPrebooking } from '../actions/qrs'
import StepBar from '../components/StepBar'

class LockerSelectorView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

      isOpen: false,
      lockers: null,
      prices: null,
      customer: null,
      qrType: "pay"
    }

    this.toggle = this.toggle.bind(this);
    this.getLockerPrice = this.getLockerPrice.bind(this);
    this.getLockerType = this.getLockerType.bind(this);
    this.getLockerPicture = this.getLockerPicture.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  goTo(lockerType, zoneSelected, descriptionZone, e) {

    const { qr, qrMode } = this.props.location.state;
    const { lockers } = this.state;

    const lockerPriceChoosed = this.getLockerPrice(lockerType)

    let hobbitAvailable = false;

    Object.keys(lockers).forEach((lockerKey) => {

      if (lockerKey === lockerType) {

        if (lockers[lockerKey].Hobbit === true) {
          hobbitAvailable = true;
        }
      }
    });

    if (hobbitAvailable) {
      this.props.push('/accessibilityView', { selectedLocker: lockerType, zoneSelected: zoneSelected, descriptionZone: descriptionZone, qr: qr, lockerPrice: lockerPriceChoosed, lockers: this.state.lockers, qrType: this.state.qrType, qrMode: qrMode })
    } else {
      const prebooking = {}

      prebooking.Qr = qr
      prebooking.LockerSelected = lockerType
      prebooking.Zone = zoneSelected
      prebooking.Accessibility = false

      this.props.postPrebooking(prebooking)
        .then((answer) => {

          const lockerAssigned = answer.response.answerPrebooking.LockerAssigned
          const lockerPin = answer.response.answerPrebooking.LockerPin
          const lockerMatricula = answer.response.answerPrebooking.LockerMatricula
          const lockerCustomer = answer.response.answerPrebooking.LockerCustomer
          const lockerCustomerID = answer.response.answerPrebooking.LockerCustomerID

          this.props.push('/PaymentView', { selectedLocker: lockerType, zoneSelected: zoneSelected, selectedAccessibility: false, descriptionZone: descriptionZone, qr: qr, lockerAssigned: lockerAssigned, lockerPrice: lockerPriceChoosed, lockerPin: lockerPin, lockerMatricula: lockerMatricula, lockerCustomer: lockerCustomer, lockerCustomerID: lockerCustomerID, qrType: this.state.qrType, qrMode: qrMode })

        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  goBack(e) {

    const { qr } = this.props.location.state;

    history.push(`/qrsale/${qr}`)

  }

  componentDidMount() {
    const { zoneSelected, descriptionZone, qr } = this.props.location.state;
    const qrtoken = qr.toString() + zoneSelected.toString()

    this.props.fetchLockers(qrtoken)
      .then((answer) => {
        let answerLockers = answer.response.answerLockers
      
        let answerDisponibility = answerLockers.Disponibility
        let answerPrices = answerLockers.Prices
        let customerName = answerLockers.Customer
        let qrType = answerLockers.qrType
        
        this.setState({ lockers: answerDisponibility, prices: answerPrices, customer: customerName, qrType: qrType });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  getLockerType(type) {

    const { t, i18n } = this.props

    switch (type) {

      case "BNS":
        return t('BÁSICA')
      case "CNS":
        return t('BÁSICA CARGA')
      case "BWS":
        return t('BÁSICA XL')
      case "CWS":
        return t('BÁSICA CARGA XL')
      case "BNM":
        return t('GRANDE')
      case "CNM":
        return t('GRANDE CARGA')
      case "BWM":
        return t('GRANDE XL')
      case "CWM":
        return t('GRANDE CARGA XL')
      case "BNL":
        return t('GRANDE')
      case "CNL":
        return t('GRANDE CARGA')
      case "BWL":
        return t('GRANDE XL')
      case "CWL":
        return t('GRANDE CARGA XL')
      case "BNXL":
        return t('GRANDE')
      case "CNXL":
        return t('GRANDE CARGA')
      case "BWXL":
        return t('GRANDE XL')
      case "CWXL":
        return t('GRANDE CARGA XL')
      case "BN2XL":
        return t('GRANDE')
      case "CN2XL":
        return t('GRANDE CARGA')
      case "BW2XL":
        return t('GRANDE XL')
      case "CW2XL":
        return t('GRANDE CARGA XL')
      case "BN3XL":
        return t('SKI')
      case "CN3XL":
        return t('SKI CARGA')
      case "BW3XL":
        return t('SKI XL')
      case "CW3XL":
        return t('SKI CARGA XL')
      default:
        return t('BÁSICA')
    }
  }

  getLockerPicture(type) {

    return `/images/locker_${type}.png`

  }

  getLockerPrice(type) {
    const { prices } = this.state;
    const lockerPriceObj = prices.find((price) => price.item === `LOCKER${type}`);

    let lockerPrice = 0

    if (lockerPriceObj) {

      lockerPrice = Number(lockerPriceObj.price);
    }

    return lockerPrice.toFixed(2)
  }

  renderLockers() {
    const { t, i18n } = this.props
    const { lockers, prices } = this.state;
    const { zoneSelected, descriptionZone } = this.props.location.state;
    const lockerDivs = []

    if (!lockers || !prices) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    } else {
      Object.keys(lockers).forEach((lockerKey) => {
        const locker = lockers[lockerKey];

        lockerDivs.push(
          <div className="col-xs-12 titleh5">
            <div className="lockButton" onClick={(e) => this.goTo(lockerKey, zoneSelected, descriptionZone, e)}>
              <div className="row">
                <div className="colLockers">
                  <img src={this.getLockerPicture(lockerKey)} alt={"locker_" + lockerKey} className="lockerSmallImage" />
                </div>
                <div className="colPrice">
                  <p className="pricesLockers">{this.getLockerPrice(lockerKey)} €</p>
                </div>
                <div className="col">
                  <h7 className="titleLockers">{t('Taquilla')}</h7>
                  <h5 className="col lockerTextDescription">{this.getLockerType(lockerKey)}</h5>
                </div>
              </div>
            </div>
          </div>
        );
      });

      if (lockerDivs.length == 0) {
        return (
          <div>
            <div className="col-xs-12 titleh5">
              <div className="lockSoldOutButton" onClick={(e) => this.goBack(e)}>
                <h5>{t('TAQUILLAS AGOTADAS')}</h5>
                <h7>{t('Haz click aquí para volver a selección de zonas')}</h7>
                <div className="lockSoldOutImgContainer">
                  <img className="lockSoldOutImg" src="/images/locker_BNS.png" alt="Locker" />
                  <img className="lockSoldOutCross" src="/images/cross.png" alt="Cross" />
                </div>
              </div>
            </div>
          </div>
        )
      }

      return (<div>{lockerDivs}</div>)
    }
  }

  render() {
    const { t, i18n, lockers } = this.props;
    const { zoneSelected, descriptionZone, customerName } = this.props.location.state;
    const { customer } = this.state;

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br></br>
          <StepBar step={2}/>
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('SELECCIONA EL TIPO DE TAQUILLA')}</span>
          </h3>
          <br />
          <h4 className="lockTitle">
            <span className="text-vw-dark">{t('PARQUE')}</span><br></br>
            <span className="text-vw-light">{customer}</span>
          </h4>
          <h4 className="lockTitle">
            <span className="text-vw-dark">{t('ZONA SELECCIONADA')}</span><br></br>
            <span className="text-vw-light">{zoneSelected + " - " + descriptionZone}</span>
          </h4>
          <br />
          {this.renderLockers()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true,
  lockers: state.lockers,
  prices: state.prices
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  fetchLockers: bindActionCreators(fetchLockers, dispatch),
  postPrebooking: bindActionCreators(postPrebooking, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(LockerSelectorView));