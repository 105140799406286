import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Link } from 'react-router-dom'
import { withNamespaces } from "react-i18next";

import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap'

import { authLogout } from '../actions/auth'
import DropdownLanguage from "../components/DropdownLanguage";

class AppHeader extends React.Component {
  constructor(props) {
    super(props)

    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.authLogout()
    this.props.push('/login')
  }

  getLanguageCode(lang) {
    switch(lang){
      case "es": return 'ES';
      case "en": return 'EN';
      case "it": return 'IT';
      case "de": return 'DE';
      case "fr": return 'FR';
      case "pt": return 'PT';
    }
  }

  render() {
    const { t, i18n } = this.props

    return (
      <Navbar className="box-shadow bg-vw-dark" dark expand="md">
        <Container>
          <NavbarBrand>
            <img className="logo" src="/images/logo.png" alt="Logo" />
          </NavbarBrand>
          <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  <i className="fas fa-globe mr-1"></i>  {this.getLanguageCode(i18n.language)}
                </DropdownToggle>
                <DropdownLanguage></DropdownLanguage>
              </UncontrolledDropdown>
            </Nav>
        </Container>
      </Navbar>
    )
  }
}

const mapStateToProps = state => ({
  auth: true
})

const mapDispatchToProps = dispatch => ({
  authLogout: bindActionCreators(authLogout, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(AppHeader));
