import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next";
import { fetchOpenings } from '../actions/openings'
import OTOStepBar from '../components/OTOStepBar'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class OTOAccessView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      openingNotFound: false,
      phoneNumber: '',
      mail: '',
      accessCode: '',
      openingID: '',
    }

    this.toggle = this.toggle.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleAccessCodeNumberChange = this.handleAccessCodeNumberChange.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleRedirect = () => {
    const { id } = this.props.match.params;
    history.push(`/qrsale/${id}`)
  };

  handlePhoneNumberChange(event) {
    const phoneNumber = event.target.value;
    this.setState({ phoneNumber });
  }

  handleAccessCodeNumberChange(event) {
    const accessCode = event.target.value;
    this.setState({ accessCode });
  }

  handleMailChange(event) {
    const mail = event.target.value;
    const isValidEmail = this.validateEmail(mail);
    this.setState({ mail, isValidEmail });
  }

  validateEmail(email) {
    // A simple email validation pattern using regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  goToRental(openingID, opens, matricula) {
    const { id } = this.props.match.params;

    if (!this.isContinueDisabled()) {
      this.props.push(`/otoOpenView/${id}`, {
        phoneNumber: this.state.phoneNumber,
        mail: this.state.mail,
        accessCode: this.state.accessCode,
        openingID: openingID,
        opens: opens,
        matricula: matricula
      });
    }
  }

  checkOpening() {
    let today = new Date();
    this.props.fetchOpenings('', '', this.state.phoneNumber, '', this.state.accessCode, today, today)
      .then((answer) => {
        console.log(answer.response)

        if(answer.response.length === 0 || answer.response[0].Opens === 0) {
          this.setState({ openingNotFound: true });
        }else{
          this.goToRental(answer.response[0]._id, answer.response[0].Opens, answer.response[0].Matricula);
        }

      })
      .catch((error) => {
        console.error(error);
      });
  }

  componentDidMount() {


  }

  isContinueDisabled() {

    const disabled = ((this.state.phoneNumber.length < 6) || (this.state.accessCode.length < 4))

    return disabled;
  }

  render() {
    const { t, i18n } = this.props;
    const { openingNotFound } = this.state;

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br></br>
          <OTOStepBar step={2} />
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-light">{t('INTRODUCE TUS DATOS DE ACCESO')}</span>
          </h3>
          <div>
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('TELÉFONO*')}</span>
            </h3>
            <input className="phoneInput"
              name="lockerPhone"
              type="tel"
              pattern="[0-9]*"
              value={this.state.phoneNumber}
              onChange={this.handlePhoneNumberChange}
            />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('CÓDIGO DE ACCESO*')}</span>
            </h3>
            <p className="lockTitle">
              <span className="text-vw-dark">{t('(Disponible en tu ticket físico o email de confirmación de reserva)')}</span>
            </p>
            <input className="phoneInput"
              name="lockerAccessCode"
              type="tel"
              pattern="[0-9]*"
              value={this.state.accessCode}
              onChange={this.handleAccessCodeNumberChange}
            />
            <br></br>
          </div>
          <br></br>
          <div className="row">
            <div
              className={`col lockerPayButton ${this.isContinueDisabled() ? "disabled" : ""}`}
              onClick={(e) => this.checkOpening()}
            >
              <h1>{t("ACCEDER")}</h1>
            </div>
          </div>
          <br></br>
          <div className="lockTitle">
            <span className="text-vw-dark">{t('*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.')}</span>
          </div>
        </div>
        <div>
            <Modal isOpen={openingNotFound}>
              <ModalHeader>
              {t('Error en la reserva')}
              </ModalHeader>
              <ModalBody>
                {t('Lo sentimos, pero tu reserva no existe o has agotado las aperturas disponibles.')}
                <div className="lockSoldOutImgContainer mb-5 flex justify-content-center align-items-center w-100">
                  <img className="lockSoldOutImg" src="/images/locker_BNS.png" alt="Locker" />
                  <img className="lockSoldOutCross" src="/images/cross.png" alt="Cross" />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="warning" className="button-center"
                  onClick={this.handleRedirect}>
                  {t('Volver al inicio')}
                </Button>
              </ModalFooter>
            </Modal>
          </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true,
  openings: state.openings.openings
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  fetchOpenings: bindActionCreators(fetchOpenings, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(OTOAccessView));
