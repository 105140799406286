import {
  DISPONIBILITY_REQUEST, DISPONIBILITY_SUCCESS, DISPONIBILITY_FAILURE,
  SALE_REQUEST, SALE_SUCCESS, SALE_FAILURE,
  AVAILABILITY_REQUEST, AVAILABILITY_SUCCESS, AVAILABILITY_FAILURE
} from '../actions/qrsbaqueira'

const INITIAL_STATE = {
  availability: null, disponibility: null, salebaqueira: null, error: null, loading: false
}

const qrsbaqueiraReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DISPONIBILITY_REQUEST:
      return {
        ...state,
        disponibility: null, error: null, loading: true
      }
    case DISPONIBILITY_SUCCESS:
      return {
        ...state,
        disponibility: action.response, error: null, loading: false
      }
    case DISPONIBILITY_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case SALE_REQUEST:
      return {
        ...state,
        salebaqueira: null, error: null, loading: true
      }
    case SALE_SUCCESS:
      return {
        ...state,
        salebaqueira: action.response, error: null, loading: false
      }
    case SALE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
      case AVAILABILITY_REQUEST:
      return {
        ...state,
        availability: null, error: null, loading: true
      }
    case AVAILABILITY_SUCCESS:
      return {
        ...state,
        availability: action.response, error: null, loading: false
      }
    case AVAILABILITY_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default qrsbaqueiraReducer

