import { CALL_API } from '../middleware/api'

export const OPENINGS_REQUEST = 'OPENINGS_REQUEST'
export const OPENINGS_SUCCESS = 'OPENINGS_SUCCESS'
export const OPENINGS_FAILURE = 'OPENINGS_FAILURE'

export function fetchOpenings(Machine, Status, Phone, Email, AccessCode, StartDate, EndDate) {
  let params = ''

  if (Machine !== undefined) {
    params += `&Machine=${Machine}`
  }

  if (Status !== undefined) {
    params += `&Status=${Status}`
  }

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  if (Phone !== undefined) {
    params += `&Phone=${Phone}`
  }

  if (Email !== undefined) {
    params += `&Email=${Email}`
  }

  if (AccessCode !== undefined) {
    params += `&AccessCode=${AccessCode}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `openings?${params}`,
      authenticated: true,
      types: [OPENINGS_REQUEST, OPENINGS_SUCCESS, OPENINGS_FAILURE]
    }
  }
}

export const OPENING_REQUEST = 'OPENING_REQUEST'
export const OPENING_SUCCESS = 'OPENING_SUCCESS'
export const OPENING_FAILURE = 'OPENING_FAILURE'

export function fetchOpening(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `openings/${id}`,
      authenticated: true,
      types: [OPENING_REQUEST, OPENING_SUCCESS, OPENING_FAILURE]
    }
  }
}

export const OPENING_UPDATE_REQUEST = 'OPENINGS_REQUEST'
export const OPENING_UPDATE_SUCCESS = 'OPENINGS_SUCCESS'
export const OPENING_UPDATE_FAILURE = 'OPENINGS_FAILURE'

export function updateOpening(ID) {
  let params = ''

  if (ID !== undefined) {
    params += `&ID=${ID}`
  }

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `openings/update?${params}`,
      authenticated: true,
      types: [OPENING_UPDATE_REQUEST, OPENING_UPDATE_SUCCESS, OPENING_UPDATE_FAILURE]
    }
  }
}
