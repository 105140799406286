import React from 'react'
import ReactDOM from 'react-dom'
import { store, history } from './store'
import Root from './containers/Root'
import { authLoginSuccess } from './actions/auth'
import './i18n';

const token = localStorage.getItem('token')
if (token !== null) {
  store.dispatch(authLoginSuccess(token))
}

ReactDOM.render(
  <Root store={store} history={history} />,
  document.getElementById('root')
);
