import React from 'react'
import { withNamespaces } from "react-i18next"

class OTOStepBar extends React.Component {

  render() {

    const step = this.props.step;

    const { t, i18n } = this.props

    return (
      <div className="row stepbar">

        <div className="stepparent">
          {step > 1 ? (
            <div className="stepOld">1</div>
          ) : (
            <div className={`${step == 1 ? "stepActive" : "step"}`}>1</div>
          )}

          <div className={`${step == 1 ? "steplabelActive" : "steplabel"}`}>{t('Gestión')}</div>
        </div>

        <div className="stepline"></div>

        <div className="stepparent">
          {step > 2 ? (
            <div className="stepOld">2</div>
          ) : (
            <div className={`${step == 2 ? "stepActive" : "step"}`}>2</div>
          )}

          <div className={`${step == 2 ? "steplabelActive" : "steplabel"}`}>{t('Acceso')}</div>
        </div>

        <div className="stepline"></div>

        <div className="stepparent">
          {step > 3 ? (
            <div className="stepOld">3</div>
          ) : (
            <div className={`${step == 3 ? "stepActive" : "step"}`}>3</div>
          )}
          
          <div className={`${step == 3 ? "steplabelActive" : "steplabel"}`}>{t('Abrir')}</div>
        </div>
        
      </div>
    )
  }
}

export default withNamespaces()(OTOStepBar)

