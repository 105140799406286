import React from 'react'
import { withNamespaces } from "react-i18next";
import ReactCountryFlag from "react-country-flag"
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap'

import { authLogout } from '../actions/auth'


// update your language in your component
class DropdownLanguage extends React.Component {
  state = { value: 'en'}

  handleLangChange = (language) => {
    this.props.i18n.changeLanguage(language);
  }
  render() {
    const { t, i18n } = this.props
    return (
      <DropdownMenu>
        <DropdownItem key="es" onClick={() => this.handleLangChange('es')}>
          <ReactCountryFlag countryCode="ES" svg /> ESPAÑOL
        </DropdownItem>
        <DropdownItem key="en" onClick={() => this.handleLangChange('en')}>
          <ReactCountryFlag countryCode="GB" svg /> ENGLISH
        </DropdownItem>
        <DropdownItem key="it" onClick={() => this.handleLangChange('it')}>
          <ReactCountryFlag countryCode="IT" svg /> ITALIANO
        </DropdownItem>
        <DropdownItem key="de" onClick={() => this.handleLangChange('de')}>
          <ReactCountryFlag countryCode="DE" svg /> DEUTSCH
        </DropdownItem>
        <DropdownItem key="fr" onClick={() => this.handleLangChange('fr')}>
          <ReactCountryFlag countryCode="FR" svg /> FRANÇAIS
        </DropdownItem>
        <DropdownItem key="pt" onClick={() => this.handleLangChange('pt')}>
          <ReactCountryFlag countryCode="PT" svg /> PORTUGUÉS
        </DropdownItem>
      </DropdownMenu>
    );
  }
}
// export default your component run through withTranslation imported above
export default withNamespaces()(DropdownLanguage)
