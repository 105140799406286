import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next"
import OTOStepBar from '../components/OTOStepBar'

class OTOSelectorView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      forwardScreen: false
    }

    this.goToZones = this.goToZones.bind(this);
    this.goToOTO = this.goToOTO.bind(this);
  }

  goToZones(e) {
    const { id } = this.props.match.params;
    const { qrMode } = this.props.location.state;

    this.setState(
      {
        forwardScreen: true,
      },
      () => {
        console.log("forwardScreen: ", this.state.forwardScreen);
        history.push(`/zoneSelectorView/${id}`, {
          qrMode: qrMode
        });
      }
    );
  }

  goToOTO(e) {
    const { id } = this.props.match.params;

    this.setState(
      {
        forwardScreen: true,
      },
      () => {
        console.log("forwardScreen: ", this.state.forwardScreen);
        history.push(`/otoAccessView/${id}`);
      }
    );
  }

  componentDidMount() {
    const { id } = this.props.match.params;

  }

  handleLeftClick = () => {
    console.log("Left side clicked");
    this.goToZones();
  };

  handleRightClick = () => {
    console.log("Right side clicked");
    this.goToOTO();
  };

  render() {
    const { t, i18n } = this.props
    const { zones, description, customerName } = this.state;

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br></br>
          <OTOStepBar step={1} />
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('¿QUE DESEAS HACER?')}</span>
          </h3>
          <button className="side" onClick={this.handleLeftClick}>
            <h5>{t('ALQUILAR NUEVA TAQUILLA')}</h5>
            <img src="/images/lockerClosed.png" alt="Alquilar" />
          </button>
          <button className="side bg-warning" onClick={this.handleRightClick}>
            <h5>{t('ACCEDER A TAQUILLA YA ALQUILADA')}</h5>
            <img src="/images/lockerOpen.png" alt="Abrir" />
          </button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(OTOSelectorView));