import React from 'react'
import ErrorMessage from '../components/ErrorMessage'

class NotFoundView extends React.Component {
  render() {
    return (
      <div>
        <div id="clouds">
          <div class="cloud x1"></div>
          <div class="cloud x1_5"></div>
          <div class="cloud x2"></div>
          <div class="cloud x3"></div>
          <div class="cloud x4"></div>
          <div class="cloud x5"></div>
        </div>
        <div class='c'>
          <div class='_404'>404</div>
          <hr />
          <div class='_1'>ESTA PÁGINA</div>
          <div class='_2'>NO EXISTE</div>
        </div>
      </div>
    )
  }
}

export default NotFoundView
