import React from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { postBookemail } from '../actions/qrs'

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class TicketView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pin: '*123456#',
      numero: '123',
      factura: 'VLK0001000001',
      iva: 21,
      importeBase: null,
      importeIva: null,
      total: null,
      date: null,
      accessCode: '',
      forwardScreen: false,
      downloadingPDF: false
    }

    this.generatePDF = this.generatePDF.bind(this);
  }

  componentDidMount() {
    const { lockerPrice, phoneNumber, mail, newsletterAcceptance, confirmReservation, zoneSelected, selectedLocker, selectedAccessibility, lockerCustomer, lockerMatricula, lockerPin, lockerInvoice, descriptionZone, lockerSelected, lockerAssigned, deliverMail } = this.props.location.state;
    const { iva, importeBase, importeIva, total, date } = this.state;

    let autorizeBookemail = true;

    const navigationEntries = window.performance.getEntriesByType('navigation');
    if ((navigationEntries.length > 0) && (navigationEntries[0].type === 'reload')) {
      console.log("Page was reloaded");
      autorizeBookemail = false;
    }

    let today = new Date().toLocaleString();

    this.setState({ date: today });

    this.TotalComponent()

    if (autorizeBookemail && deliverMail) {
      let bookEmail = {}

      bookEmail.bookEmail = mail
      bookEmail.lockerMatricula = lockerMatricula
      bookEmail.lockerAssigned = lockerAssigned
      bookEmail.phoneNumber = phoneNumber
      bookEmail.lockerPin = lockerPin
      bookEmail.zoneSelected = zoneSelected
      bookEmail.selectedLocker = selectedLocker
      bookEmail.selectedAccessibility = selectedAccessibility
      bookEmail.descriptionZone = descriptionZone
      bookEmail.lockerSelected = this.getLockerType(lockerSelected)
      bookEmail.lockerCustomer = lockerCustomer
      bookEmail.lockerInvoice = lockerInvoice
      bookEmail.date = today
      bookEmail.iva = iva
      bookEmail.importeBase = (lockerPrice / (1 + (iva / 100))).toFixed(2)
      bookEmail.importeIva = (lockerPrice - (lockerPrice / (1 + (iva / 100)))).toFixed(2)
      bookEmail.total = lockerPrice

      this.props.postBookemail(bookEmail)
        .then((answer) => {
          let accessCode = answer.response.openingAccessCode

          this.setState({ accessCode: accessCode });
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }

  componentWillUnmount() {
    const { parque, reserva, paymentId, qrToken, lockerPrice, phoneNumber, mail, newsletterAcceptance, confirmReservation, zoneSelected, selectedLocker, selectedAccessibility, lockerCustomer, lockerMatricula, lockerPin, lockerInvoice, descriptionZone, lockerSelected, lockerAssigned, qrMode } = this.props.location.state;

    this.props.push('/ticketView', {
      phoneNumber: phoneNumber,
      mail: 'dsouto@vacway.es',
      newsletterAcceptance: newsletterAcceptance,
      confirmReservation: confirmReservation,
      parque: parque,
      reserva: reserva,
      lockerSelected: lockerSelected,
      lockerPrice: lockerPrice,
      descriptionZone: descriptionZone,
      selectedAccessibility: selectedAccessibility,
      zoneSelected: zoneSelected,
      selectedLocker: selectedLocker,
      lockerAssigned: lockerAssigned,
      lockerPrice: lockerPrice,
      paymentId: paymentId,
      qrToken: qrToken,
      lockerPin: lockerPin,
      lockerMatricula: lockerMatricula,
      lockerCustomer: lockerCustomer,
      lockerInvoice: lockerInvoice,
      deliverMail: false,
      qrMode: qrMode,
    });
  }

  getImageDataURL = (imageUrl) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const image = new Image();

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0);

        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };

      image.onerror = (error) => {
        reject(error);
      };

      image.src = imageUrl;
    });
  };

  TotalComponent() {
    const { iva } = this.state;
    const { lockerPrice } = this.props.location.state;

    let importeBase = (lockerPrice / (1 + (iva / 100))).toFixed(2)
    let importeIva = (lockerPrice - importeBase).toFixed(2)
    let total = lockerPrice

    this.setState({ importeBase: importeBase, importeIva: importeIva, total: total });

    return { total, importeBase, importeIva, iva };
  }

  openLocker(qrToken) {

    console.log("forwardScreen: ", this.state.forwardScreen);

    window.location.href = `https://lockers-qr.vacway.es/qrsale/${qrToken}`;

  }

  getLockerType(type) {

    const { t, i18n } = this.props

    switch (type) {

      case "BNS":
        return t('BÁSICA')
      case "CNS":
        return t('BÁSICA CARGA')
      case "BWS":
        return t('BÁSICA XL')
      case "CWS":
        return t('BÁSICA CARGA XL')
      case "BNM":
        return t('GRANDE')
      case "CNM":
        return t('GRANDE CARGA')
      case "BWM":
        return t('GRANDE XL')
      case "CWM":
        return t('GRANDE CARGA XL')
      case "BNL":
        return t('GRANDE')
      case "CNL":
        return t('GRANDE CARGA')
      case "BWL":
        return t('GRANDE XL')
      case "CWL":
        return t('GRANDE CARGA XL')
      case "BNXL":
        return t('GRANDE')
      case "CNXL":
        return t('GRANDE CARGA')
      case "BWXL":
        return t('GRANDE XL')
      case "CWXL":
        return t('GRANDE CARGA XL')
      case "BN2XL":
        return t('GRANDE')
      case "CN2XL":
        return t('GRANDE CARGA')
      case "BW2XL":
        return t('GRANDE XL')
      case "CW2XL":
        return t('GRANDE CARGA XL')
      case "BN3XL":
        return t('SKI')
      case "CN3XL":
        return t('SKI CARGA')
      case "BW3XL":
        return t('SKI XL')
      case "CW3XL":
        return t('SKI CARGA XL')
      default:
        return t('BÁSICA')
    }
  }

  render() {
    const { t, i18n } = this.props
    const { iva, importeBase, importeIva, total, date, accessCode, downloadingPDF } = this.state;
    const { zoneSelected, selectedLocker, selectedAccessibility, lockerCustomer, lockerMatricula, lockerPin, lockerInvoice, descriptionZone, lockerSelected, qrToken } = this.props.location.state;

    const qrMode = 0; //HARDCODED

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('¡GRACIAS POR RESERVAR TU TAQUILLA!')}</span>
          </h3>
          <div className="lockTitle">
            <span className="text-vw-dark">{t('Sigue las instrucciones que encontrarás a continuación o en tu email.')}</span>
          </div>
          <br></br>
          <div className="lockerTicket">
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('TICKET')}</span>
            </h3>
            <hr className="bg-vw-dark" />
            <h4 className="lockTitle">
              <span className="text-vw-dark">{t('Nº TAQUILLA')}</span>
            </h4>
            <h3 className="lockTitle">
              <span className="text-vw-light">{lockerMatricula}</span>
            </h3>
            <h4 className="lockTitle">
              {qrMode === 0 ? (
                <span className="text-vw-dark">{t('PIN')}</span>
              ) : (
                <span className="text-vw-dark">{t('CÓDIGO DE ACCESO')}</span>
              )
              }
            </h4>
            <h3 className="lockTitle">
              {qrMode === 0 ? (
                <span className="text-vw-light">{lockerPin}</span>
              ) : (
                <span className="text-vw-light">{accessCode}</span>
              )
              }
            </h3>
            <hr className="bg-vw-dark" />
            <h6 className="lockTitle">
              <span className="text-vw-dark">{t('ZONA')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-light">{zoneSelected} - {descriptionZone}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark">{t('TIPO')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-light">{this.getLockerType(lockerSelected)}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark">{t('UBICACIÓN')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-light">{lockerCustomer}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark">{t('FACTURA')}: </span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-light">{lockerInvoice}</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark">{date}</span>
            </h6>
            <hr className="bg-vw-dark" />
            <h6 className="lockTitle">
              <span className="text-vw-dark">{t('IVA')}: {iva}%</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark">{t('IMPORTE BASE')}: {importeBase}€</span>
            </h6>
            <h6 className="lockTitle">
              <span className="text-vw-dark">{t('IMPORTE IVA')}: {importeIva}€</span>
            </h6>
            <hr className="bg-vw-dark" />
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('TOTAL')}: {total}€</span>
            </h3>
          </div>
          <div className="lockTitle">
            <span className="text-vw-dark">{t('*Si deseas obtener el Pin en formato físico para llevarlo durante tu estancia en el parque contacta con los responsables de la zona de taquillas y enséñales tu email de reserva.')}</span>
          </div>
          <br></br>
          {downloadingPDF ? (
            <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
          ) : (
            <div className="row mt-5">
              <div className="col lockerDownloadButton" onClick={this.generatePDF}>
                <h3>{t('DESCARGAR TICKET')}</h3>
              </div>
            </div>
          )
          }
          {qrMode === 0 ? (
            <div></div>
          ) : (
            <div className="row mt-5 mb-5">
              <div className="col lockerOpenButton" onClick={() => this.openLocker(qrToken)}>
                <h3>{t('ABRIR TAQUILLA')}</h3>
              </div>
            </div>
          )
          }
          {qrMode === 0 ? (
            <div className="lockerInstructions">
              <h3 className="lockTitle">
                <span className="text-vw-dark">{t('INSTRUCCIONES')}</span>
              </h3>
              <hr className="bg-vw-dark" />
              <div className="row">
                <div className="col flex align-items-center">
                  <img src="/images/lockerBasicClosedStep1.svg" alt="Locker Charger" className="lockerTicketImage" />
                </div>
                <div className="col">
                  <h5>
                    <span className="text-vw-light">{t('PASO 1')}</span>
                  </h5>
                  <h5>
                    <span className="text-vw-dark">{t('LOCALIZA TU TAQUILLA')}</span>
                  </h5>
                </div>
              </div>
              <hr className="bg-vw-dark" />
              <div className="row">
                <div className="col">
                  <h5>
                    <span className="text-vw-light">{t('PASO 2')}</span>
                  </h5>
                  <h5>
                    <span className="text-vw-dark">{t('TOCA EL TECLADO')}</span>
                  </h5>
                </div>
                <div className="col flex align-items-center">
                  <img src="/images/lockerBasicClosedStep2.svg" alt="Locker Charger" className="lockerTicketImage" />
                </div>
              </div>
              <hr className="bg-vw-dark" />
              <div className="row">
                <div className="col flex align-items-center">
                  <img src="/images/lockerBasicClosedStep3.svg" alt="Locker Charger" className="lockerTicketImage" />
                </div>
                <div className="col">
                  <h5>
                    <span className="text-vw-light">{t('PASO 3')}</span>
                  </h5>
                  <h5>
                    <span className="text-vw-dark">{t('INTRODUCE TU PIN')}</span>
                  </h5>
                </div>
              </div>
              <hr className="bg-vw-dark" />
              <div className="row">
                <div className="col">
                  <h5>
                    <span className="text-vw-light">{t('PASO 4')}</span>
                  </h5>
                  <h5>
                    <span className="text-vw-dark">{t('ABRE LAS VECES QUE QUIERAS')}</span>
                  </h5>
                </div>
                <div className="col flex align-items-center">
                  <img src="/images/lockerBasicOpenedStep4.svg" alt="Locker Charger" className="lockerTicketImage" />
                </div>
              </div>
            </div>
          ) : (
            <div className="lockerInstructions">
              <h3 className="lockTitle">
                <span className="text-vw-dark">{t('INSTRUCCIONES')}</span>
              </h3>
              <hr className="bg-vw-dark" />
              <div className="row">
                <div className="col flex align-items-center">
                  <img src="/images/qr.png" alt="Locker Charger" className="lockerTicketImage" />
                </div>
                <div className="col">
                  <h5>
                    <span className="text-vw-light">{t('PASO 1')}</span>
                  </h5>
                  <h5>
                    <span className="text-vw-dark">{t('APRIETA "ABRIR TAQUILLA" O ESCANEA EL QR')}</span>
                  </h5>
                </div>
              </div>
              <hr className="bg-vw-dark" />
              <div className="row">
                <div className="col">
                  <h5>
                    <span className="text-vw-light">{t('PASO 2')}</span>
                  </h5>
                  <h5>
                    <span className="text-vw-dark">{t('ACCEDE CON TUS CREDENCIALES')}</span>
                  </h5>
                </div>
                <div className="col flex align-items-center">
                  <img src="/images/lockerOTOStep2.png" alt="Locker Charger" className="lockerTicketImage" />
                </div>
              </div>
              <hr className="bg-vw-dark" />
              <div className="row">
                <div className="col flex align-items-center">
                  <img src="/images/lockerOpen.png" alt="Locker Charger" className="lockerTicketImage" />
                </div>
                <div className="col">
                  <h5>
                    <span className="text-vw-light">{t('PASO 3')}</span>
                  </h5>
                  <h5>
                    <span className="text-vw-dark">{t('SELECCIONA ABRIR AHORA')}</span>
                  </h5>
                </div>
              </div>
              <hr className="bg-vw-dark" />
              <div className="row">
                <div className="col">
                  <h5>
                    <span className="text-vw-light">{t('PASO 4')}</span>
                  </h5>
                  <h5>
                    <span className="text-vw-dark">{t('DISPONES DE 2 APERTURAS')}</span>
                  </h5>
                </div>
                <div className="col flex align-items-center">
                  <img src="/images/lockerBasicOpenedStep4.svg" alt="Locker Charger" className="lockerTicketImage" />
                </div>
              </div>
            </div>
          )
          }
        </div>
      </div>
    )
  }


  generatePDF = async () => {
    const { iva, importeBase, importeIva, total, date, accessCode } = this.state;
    const { zoneSelected, selectedLocker, selectedAccessibility, lockerCustomer, lockerMatricula, lockerPin, lockerInvoice, descriptionZone, lockerSelected} = this.props.location.state;

    const qrMode = 0; //HARDCODED
    
    const { t, i18n } = this.props

    this.setState({ downloadingPDF: true });

    const logocolorDataURL = await this.getImageDataURL('/images/logocolor.png');

    const step1DataURL = await this.getImageDataURL('/images/lockerBasicClosedStep1.png');
    const step2DataURL = await this.getImageDataURL('/images/lockerBasicClosedStep2.png');
    const step3DataURL = await this.getImageDataURL('/images/lockerBasicClosedStep3.png');
    const step4DataURL = await this.getImageDataURL('/images/lockerBasicOpenedStep4.png');

    const step1OTODataURL = await this.getImageDataURL('/images/qr.png');
    const step2OTODataURL = await this.getImageDataURL('/images/lockerOTOStep2.png');
    const step3OTODataURL = await this.getImageDataURL('/images/lockerOpen.png');
    const step4OTODataURL = await this.getImageDataURL('/images/lockerBasicOpenedStep4.png');

    const docDefinition = {
      footer: {
        columns: [
          { text: `${t('Más información y garantía en www.vacway.com')}`, style: 'documentFooterCenter' },
        ]
      },
      content: [
        {
          columns: [
            {
              image: logocolorDataURL,
              width: 150
            },

            [
              {
                text: `${t('TICKET')}`,
                style: 'invoiceTitle',
                width: '*'
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: `${t('Factura Simplificada')}`,
                        style: 'invoiceSubTitle',
                        width: '*'

                      },
                      {
                        text: `${lockerInvoice}`,
                        style: 'invoiceSubValue',
                        width: 150

                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        text: `${t('Fecha y Hora')}`,
                        style: 'invoiceSubTitle',
                        width: '*'
                      },
                      {
                        text: new Date().toLocaleString(),
                        style: 'invoiceSubValue',
                        width: 150
                      }
                    ]
                  },
                ]
              }
            ],
          ],
        },
        {
          columns: [
            {
              text: `${t('INFORMACIÓN DE TU LOCKER:')}`,
              style: 'invoiceBillingTitle',

            },
            {
              text: 'VACWAY WATERPROOF S.L',
              style: 'invoiceBillingTitle',

            },
          ]
        },
        {
          columns: [
            {
              text: `${t('Nº TAQUILLA')}:  ${lockerMatricula}\n ${t('PIN')}: ${lockerPin}\n ${t('ZONA')}: ${zoneSelected} \n ${t('TIPO')}: ${this.getLockerType(lockerSelected)}\n ${t('ACCESIBLE')}: ${selectedAccessibility} \n ${t('UBICACIÓN')}: ${lockerCustomer}`,
              style: 'invoiceBillingDetails'
            },
            {
              text: 'Albert Einstein, 46 \n 08223 Terrassa \n Barcelona, España \n NIF: B66983032',
              style: 'invoiceBillingDetails'
            },
          ]
        },
        '\n\n',
        {
          table: {
            headerRows: 1,
            widths: ['*', 40, 'auto', 40, 'auto', 80],

            body: [
              [
                {
                  text: `${t('Producto')}`,
                  style: 'itemsHeader'
                },
                {
                  text: `${t('UNIDADES')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IMPORTE BASE')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IVA')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IMPORTE IVA')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('TOTAL')}`,
                  style: ['itemsHeader', 'center']
                }
              ],
              [
                [
                  {
                    text: `${this.getLockerType(lockerSelected)}`,
                    style: 'itemTitle'
                  },

                ],
                {
                  text: '1',
                  style: 'itemNumber'
                },
                {
                  text: `${importeBase}`,
                  style: 'itemNumber'
                },
                {
                  text: `${iva}%`,
                  style: 'itemNumber'
                },
                {
                  text: `${importeIva}`,
                  style: 'itemNumber'
                },
                {
                  text: `${total}`,
                  style: 'itemTotal'
                }
              ],
              // END Items
            ]
          },
          layout: 'lightHorizontalLines'
        },
        {
          text: `${t('PASO 1')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step1DataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('LOCALIZA TU TAQUILLA')}`,
            },
          ],

        },
        {
          text: `${t('PASO 2')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step2DataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('TOCA EL TECLADO')}`,
            },
          ],

        },
        {
          text: `${t('PASO 3')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step3DataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('INTRODUCE TU PIN')}`,
            },
          ],

        },
        {
          text: `${t('PASO 4')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step4DataURL,
              height: 80,
              width: 120,
            },

            {
              text: `\n${t('ABRE LAS VECES QUE QUIERAS')}`,
            },
          ],

        },
      ],

      styles: {
        // Document Footer
        documentFooterLeft: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'left'
        },
        documentFooterCenter: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'center'
        },
        documentFooterRight: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'right'
        },
        invoiceTitle: {
          fontSize: 22,
          bold: true,
          alignment: 'right',
          margin: [0, 0, 0, 15]
        },
        instrucciones: {
          fontSize: 22,
          bold: true,
          alignment: 'left',
          margin: [0, 0, 0, 15]
        },
        invoiceSubTitle: {
          fontSize: 12,
          alignment: 'right'
        },
        invoiceSubValue: {
          fontSize: 12,
          alignment: 'right'
        },
        invoiceBillingTitle: {
          fontSize: 14,
          bold: true,
          alignment: 'left',
          margin: [0, 20, 0, 5],
        },
        invoiceBillingDetails: {
          alignment: 'left',
          bold: true

        },
        itemsHeader: {
          margin: [0, 5, 0, 5],
          bold: true
        },
        itemTitle: {
          bold: true,
        },
        itemNumber: {
          margin: [0, 5, 0, 5],
          alignment: 'center',
        },
        itemTotal: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },

        itemsFooterSubTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterSubValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
        foto1: {
          alignment: 'left',
        },
        itemsFooterTotalTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterTotalValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
        signaturePlaceholder: {
          margin: [0, 70, 0, 0],
        },
        signatureName: {
          bold: true,
          alignment: 'center',
        },
        signatureJobTitle: {
          italics: true,
          fontSize: 10,
          alignment: 'center',
        },
        notesTitle: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 3],
          alignment: 'center',
        },
        notesText: {
          fontSize: 10
        },
        center: {
          alignment: 'center',
        },
      },
      defaultStyle: {
        columnGap: 20,
      }

    };

    const docOTODefinition = {
      footer: {
        columns: [
          { text: `${t('Más información y garantía en www.vacway.com')}`, style: 'documentFooterCenter' },
        ]
      },
      content: [
        {
          columns: [
            {
              image: logocolorDataURL,
              width: 150
            },

            [
              {
                text: `${t('TICKET')}`,
                style: 'invoiceTitle',
                width: '*'
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: `${t('Factura Simplificada')}`,
                        style: 'invoiceSubTitle',
                        width: '*'

                      },
                      {
                        text: `${lockerInvoice}`,
                        style: 'invoiceSubValue',
                        width: 150

                      }
                    ]
                  },
                  {
                    columns: [
                      {
                        text: `${t('Fecha y Hora')}`,
                        style: 'invoiceSubTitle',
                        width: '*'
                      },
                      {
                        text: new Date().toLocaleString(),
                        style: 'invoiceSubValue',
                        width: 150
                      }
                    ]
                  },
                ]
              }
            ],
          ],
        },
        {
          columns: [
            {
              text: `${t('INFORMACIÓN DE TU LOCKER:')}`,
              style: 'invoiceBillingTitle',

            },
            {
              text: 'VACWAY WATERPROOF S.L',
              style: 'invoiceBillingTitle',

            },
          ]
        },
        {
          columns: [
            {
              text: `${t('Nº TAQUILLA')}:  ${lockerMatricula}\n ${t('CÓDIGO DE ACCESO')}: ${accessCode}\n ${t('ZONA')}: ${zoneSelected} \n ${t('TIPO')}: ${this.getLockerType(lockerSelected)}\n ${t('ACCESIBLE')}: ${selectedAccessibility} \n ${t('UBICACIÓN')}: ${lockerCustomer}`,
              style: 'invoiceBillingDetails'
            },
            {
              text: 'Albert Einstein, 46 \n 08223 Terrassa \n Barcelona, España \n NIF: B66983032',
              style: 'invoiceBillingDetails'
            },
          ]
        },
        '\n\n',
        {
          table: {
            headerRows: 1,
            widths: ['*', 40, 'auto', 40, 'auto', 80],

            body: [
              [
                {
                  text: `${t('Producto')}`,
                  style: 'itemsHeader'
                },
                {
                  text: `${t('UNIDADES')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IMPORTE BASE')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IVA')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('IMPORTE IVA')}`,
                  style: ['itemsHeader', 'center']
                },
                {
                  text: `${t('TOTAL')}`,
                  style: ['itemsHeader', 'center']
                }
              ],
              [
                [
                  {
                    text: `${this.getLockerType(lockerSelected)}`,
                    style: 'itemTitle'
                  },

                ],
                {
                  text: '1',
                  style: 'itemNumber'
                },
                {
                  text: `${importeBase}`,
                  style: 'itemNumber'
                },
                {
                  text: `${iva}%`,
                  style: 'itemNumber'
                },
                {
                  text: `${importeIva}`,
                  style: 'itemNumber'
                },
                {
                  text: `${total}`,
                  style: 'itemTotal'
                }
              ],
              // END Items
            ]
          },
          layout: 'lightHorizontalLines'
        },
        {
          text: `${t('PASO 1')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step1OTODataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('APRIETA "ABRIR TAQUILLA" O ESCANEA EL QR')}`,
            },
          ],

        },
        {
          text: `${t('PASO 2')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step2OTODataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('ACCEDE CON TUS CREDENCIALES')}`,
            },
          ],

        },
        {
          text: `${t('PASO 3')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step3OTODataURL,
              height: 80,
              width: 80,
            },

            {
              text: `\n${t('SELECCIONA ABRIR AHORA')}`,
            },
          ],

        },
        {
          text: `${t('PASO 4')}`,
          style: 'notesTitle',
          width: '*'
        },
        {
          columns: [
            {
              image: step4OTODataURL,
              height: 80,
              width: 120,
            },

            {
              text: `\n${t('DISPONES DE 2 APERTURAS')}`,
            },
          ],

        },
      ],

      styles: {
        // Document Footer
        documentFooterLeft: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'left'
        },
        documentFooterCenter: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'center'
        },
        documentFooterRight: {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: 'right'
        },
        invoiceTitle: {
          fontSize: 22,
          bold: true,
          alignment: 'right',
          margin: [0, 0, 0, 15]
        },
        instrucciones: {
          fontSize: 22,
          bold: true,
          alignment: 'left',
          margin: [0, 0, 0, 15]
        },
        invoiceSubTitle: {
          fontSize: 12,
          alignment: 'right'
        },
        invoiceSubValue: {
          fontSize: 12,
          alignment: 'right'
        },
        invoiceBillingTitle: {
          fontSize: 14,
          bold: true,
          alignment: 'left',
          margin: [0, 20, 0, 5],
        },
        invoiceBillingDetails: {
          alignment: 'left',
          bold: true

        },
        itemsHeader: {
          margin: [0, 5, 0, 5],
          bold: true
        },
        itemTitle: {
          bold: true,
        },
        itemNumber: {
          margin: [0, 5, 0, 5],
          alignment: 'center',
        },
        itemTotal: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },

        itemsFooterSubTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterSubValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
        foto1: {
          alignment: 'left',
        },
        itemsFooterTotalTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterTotalValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
        signaturePlaceholder: {
          margin: [0, 70, 0, 0],
        },
        signatureName: {
          bold: true,
          alignment: 'center',
        },
        signatureJobTitle: {
          italics: true,
          fontSize: 10,
          alignment: 'center',
        },
        notesTitle: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 3],
          alignment: 'center',
        },
        notesText: {
          fontSize: 10
        },
        center: {
          alignment: 'center',
        },
      },
      defaultStyle: {
        columnGap: 20,
      }

    };

    pdfMake.createPdf(qrMode === 0 ? docDefinition : docOTODefinition).download(`VACWAYlockers-${lockerInvoice}.pdf`);
    this.setState({ downloadingPDF: false });
  }

}

const mapStateToProps = state => ({
  auth: true
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  postBookemail: bindActionCreators(postBookemail, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(TicketView));