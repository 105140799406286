import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { renderInputField } from '../utils/forms'
import {withNamespaces} from "react-i18next";

class LoginForm extends React.Component {

  render() {
    const { error, handleSubmit, pristine, submitting, i18n, t } = this.props
    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Field name="email"
                       type="text"
                       component={renderInputField}
                       label="E-mail"
                />
                <Field name="password"
                       type="password"
                       component={renderInputField}
                       label="Password"/>
                <button className="btn bg-vw-light text-white mr-1 mb-1" type="submit"
                        disabled={pristine || submitting}>
                    {t('Log in')}
                </button>

                { error && <div className="alert alert-danger">{error}</div> }
            </form>
        </div>

    )
  }
}

LoginForm = reduxForm({
  form: 'LoginForm',
  enableReinitialize: true
})(withNamespaces()(LoginForm))

export default LoginForm;

