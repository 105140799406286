import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next"
import OTOStepBar from '../components/OTOStepBar'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { updateOpening, fetchOpening } from '../actions/openings'

class OTOOpenView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      forwardScreen: false,
      isOpenPopup: false,
      openingID: '',
      opens: 0,
      opening: false,
      alreadyOpen: false,
      minutes: 5,
      seconds: 0,
    }

    this.goTo = this.goTo.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const { phoneNumber, mail, accessCode, openingID, opens, matricula } = this.props.location.state;

    this.setState({ openingID: openingID, opens: opens, matricula: matricula });

  }

  startCountdown = () => {
    this.countdownInterval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
      } else {
        if (this.state.minutes > 0) {
          this.setState((prevState) => ({
            minutes: prevState.minutes - 1,
            seconds: 59,
          }));
        } else {
          clearInterval(this.countdownInterval);
        }
      }
    }, 1000);
  };

  goTo(e) {
    const { id } = this.props.match.params;

    this.setState(
      {
        forwardScreen: true,
      },
      () => {
        console.log("forwardScreen: ", this.state.forwardScreen);

        history.push(`/qrsale/${id}`);

      }
    );
  }

  toggleOpenPopup() {
    this.setState({
      isOpenPopup: !this.state.isOpenPopup
    })
  }

  handleLeftClick = () => {
    console.log("Left side clicked");
    this.toggleOpenPopup();
  };

  handleRightClick = () => {
    console.log("Right side clicked");
    this.goTo();
  };

  handleOpen = () => {
    console.log("Open started");

    this.startCountdown();

    this.setState({
      opening: !this.state.opening
    });

    this.props.updateOpening(this.state.openingID)
      .then((updateAnswer) => {
        console.log(updateAnswer.response);

        // Define a function to repeatedly check the status
        const checkStatus = () => {
          this.props.fetchOpening(this.state.openingID)
            .then((fetchAnswer) => {
              console.log(fetchAnswer.response);

              if (fetchAnswer.response.Status === 'COMPLETE' || fetchAnswer.response.Status === 'ONPROCESS') {
                clearInterval(statusInterval); // Stop checking status
                this.setState({ opening: false, alreadyOpen: true });
              }
            })
            .catch((error) => {
              console.error(error);
              clearInterval(statusInterval); // Stop checking if there is an error
            });
        };

        // Start checking status every 5 seconds
        const statusInterval = setInterval(checkStatus, 5000);

        // Set a timer to run updateOpening again after 1 minute if status hasn't changed
        setTimeout(() => {
          clearInterval(statusInterval); // Clear the interval checking status
          console.log("Re-launching updateOpening after 1 minute.");
          // this.props.updateOpening(this.state.openingID)
          //   .then(response => console.log("Updated Opening Status: ", response))
          //   .catch(error => console.error("Error re-updating status: ", error));
        }, 60000);

      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const { t, i18n } = this.props
    const { zones, description, customerName, opens, matricula, minutes, seconds, } = this.state;

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br></br>
          <OTOStepBar step={3} />
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-light">{t('TAQUILLA: ')}</span><br></br>
            <span className="text-vw-dark">{matricula}</span>
          </h3>
          <h3 className="lockTitle">
            <span className="text-vw-light">{t('APERTURAS RESTANTES: ')}</span><br></br>
            <span className="text-vw-dark">{opens}</span>
          </h3>
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('¿CUANDO QUIERES ABRIR TU TAQUILLA?')}</span>
          </h3>
          <button className="side" onClick={this.handleLeftClick}>
            <h3>{t('ABRIR AHORA')}</h3>
            <img src="/images/lockerOpen.png" alt="Alquilar" />
          </button>
          <button className="side" onClick={this.handleRightClick}>
            <h3>{t('ABRIR MÁS TARDE')}</h3>
            <img src="/images/later.png" alt="Abrir" />
          </button>

          <div>
            <Modal isOpen={this.state.isOpenPopup}
              toggle={this.toggleOpenPopup}>
              {this.state.alreadyOpen ? (
                <ModalBody className="text-center">
                  <h3>{t('¡TAQUILLA ABIERTA!')}</h3>
                  <br></br>
                  <img src="/images/lockerOpen.png" alt="Abrir" style={{ width: '40vw' }}/>
                  <br></br>
                  <button className="btn btn-secondary mt-5" onClick={this.handleRightClick}>
                    <h3>{t('FINALIZAR')}</h3>
                  </button>
                </ModalBody>) : (
                this.state.opening ? (
                  <ModalBody className="text-center">
                    <h3>{t('ABRIENDO TAQUILLA')}</h3>
                    <p>{t('-Por favor tenga controlada su taquilla.')}</p>
                    <hr className="bg-vw-light" />
                    <div className="countdownTPV">
                      <p>{t('Timeout de apertura')}</p>
                      {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                    </div>
                    <br></br>
                    <div className="mt-5 mb-5 justify-content-center align-items-center flex">
                      <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
                    </div>
                  </ModalBody>) : (
                  <ModalBody className="text-center">
                    <h3>{t('¡LEER ANTES DE ABRIR!')}</h3>
                    <p>{t('-Confirma que está usted delante de la taquilla.')}</p>
                    <p>{t('-La taquilla se abrirá automáticamente al pulsar el botón.')}</p>
                    <p>{t('-Una vez abierta el alquiler quedará finalizado, retire todas sus pertenencias y cierre la taquilla.')}</p>
                    <div className="mt-5 mb-5 justify-content-center align-items-center flex">
                      <button className="btn btn-info mb-5" onClick={this.handleOpen}>
                        <h3>{t('ABRIR AHORA')}</h3>
                      </button>
                      <br></br>
                      <button className="btn btn-secondary" onClick={this.handleLeftClick}>
                        <h3>{t('CANCELAR')}</h3>
                      </button>
                    </div>
                    <p>{t('*Vacway no se hace responsable del mal uso por parte del usuario de esta funcionalidad. La aplicación está pensada para un uso presencial, controlando por parte del usuario la taquilla en todo momento. Vacway no se responsabiliza de cualquier robo o pérdida de los artículos depositados en la taquilla debido a la mala interpretación del uso de la aplicación.')}</p>
                  </ModalBody>))}

            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  updateOpening: bindActionCreators(updateOpening, dispatch),
  fetchOpening: bindActionCreators(fetchOpening, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(OTOOpenView));