import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { fetchAvailability, postCancelPrebooking } from '../actions/qrs'
import StepBar from '../components/StepBar'

class ZoneSelectorView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      isMapFullscreen: false,
      zones: null,
      description: null,
      customerName: null,
      forwardScreen: false
    }

    this.toggle = this.toggle.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  toggleMapFullscreen() {
    this.setState({
      isMapFullscreen: !this.state.isMapFullscreen
    })
  }

  goTo(zoneNumber, descriptionZone, e) {
    const { id } = this.props.match.params;
    const { qrMode } = this.props.location.state;

    this.setState(
      {
        forwardScreen: true,
      },
      () => {
        console.log("forwardScreen: ", this.state.forwardScreen);
        history.push('/lockerSelectorView', { zoneSelected: zoneNumber, descriptionZone: descriptionZone, qr: id, customerName: this.state.customerName, qrMode: qrMode });
      }
    );
  }

  componentDidMount() {

    const { id } = this.props.match.params;
    const searchParams = new URLSearchParams(this.props.location.search);
    const lockerAssigned = searchParams.get('lockerAssigned');

    let autorizeCancelPrebooking = true;

    const navigationEntries = window.performance.getEntriesByType('navigation');
    if ((navigationEntries.length > 0) && (navigationEntries[0].type === 'reload')) {
      console.log("Page was reloaded");
      autorizeCancelPrebooking = false;
    }

    if (lockerAssigned && autorizeCancelPrebooking) {

      const cancelprebooking = {}

      cancelprebooking.LockerAssigned = lockerAssigned

      this.props.postCancelPrebooking(cancelprebooking)
        .then((answer) => {

          this.props.fetchAvailability(id)
            .then((answer) => {

              let answerZones = answer.response.answerZones

              if (answerZones.multipleZones) {
                this.setState({ zones: answerZones.multipleZones });
                this.setState({ description: answerZones.descriptionZones });
              } else if (answerZones.singleZone) {
                this.goTo(answerZones.singleZone, answerZones.descriptionZones[(answerZones.singleZone) - 1], 0)
              }

              this.setState({ customerName: answerZones.customerName });
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      this.props.fetchAvailability(id)
        .then((answer) => {

          let answerZones = answer.response.answerZones

          if (answerZones.multipleZones) {
            this.setState({ zones: answerZones.multipleZones });
            this.setState({ description: answerZones.descriptionZones });
          } else if (answerZones.singleZone) {
            this.goTo(answerZones.singleZone, answerZones.descriptionZones[(answerZones.singleZone) - 1], 0)
          }

          this.setState({ customerName: answerZones.customerName });
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }

  getMapPicture(type) {
    switch (type) {
      case 0:
        return '/images/maps/mapaportaventura.svg'
      default:
        return '/images/maps/mapaportaventura.svg'
    }
  }

  renderZones() {
    const { t, i18n } = this.props
    const { zones, description, customerName } = this.state;
    const zoneDivs = []

    if (!zones || !description) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    } else {
      zones.map((zone) => (

        zoneDivs.push(
          <div className="col-xs-12" key={zone}>
            <div className="lockButton" onClick={(e) => this.goTo(zone, description[zone - 1], e)}>
              <div className="row">
                <div className="col col-4">
                  <img src="/images/Ping.svg" alt={"Ping " + zone} className="pingImage" />
                  <figcaption class='h5 numping'>{t(zone)}</figcaption>
                </div>
                <div className="col col-8 zoneDescription">
                  <h5>{t('ZONA ') + zone}</h5>
                  <p>{t(description[zone - 1])}</p>
                </div>
              </div>
            </div>
          </div>
        )
      ));

      return (<div>{zoneDivs}</div>)
    }
  }

  render() {
    const { t, i18n } = this.props
    const { zones, description, customerName } = this.state;

    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br></br>
          <StepBar step={1}/>
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('BIENVENIDO A')}</span>
            <br></br>
            <span className="text-vw-light">{customerName}</span>
          </h3>
          <h3 className="lockTitle">
            <span className="text-vw-dark">{t('SELECCIONA LA ZONA DE TAQUILLAS')}</span>
          </h3>

          {this.renderZones()}

          {/* <div className="col-xs-12">
            <Button className="mapButton" onClick={() => this.toggleMapFullscreen()}>VER MAPA DE ZONAS</Button>
          </div> */}

          <div>
            <Modal isOpen={this.state.isMapFullscreen}
              toggle={this.toggleMapFullscreen}>
              <ModalHeader toggle={this.toggleMapFullscreen}>
                MAPA DEL PARQUE
              </ModalHeader>
              <ModalBody>
                <img src={this.getMapPicture(0)} alt="Mapa" className="fullscreenMapImage" />
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={() => this.toggleMapFullscreen()}>
                  Cerrar
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true,
  availability: state.availability
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  fetchAvailability: bindActionCreators(fetchAvailability, dispatch),
  postCancelPrebooking: bindActionCreators(postCancelPrebooking, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(ZoneSelectorView));