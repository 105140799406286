import createHistory from 'history/createBrowserHistory'
import thunkMiddleware from 'redux-thunk'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { routerMiddleware, routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import { createLogger } from 'redux-logger'

import apiMiddleware from '../middleware/api'
import authReducer from '../reducers/auth'
import usersReducer from '../reducers/users'
import qrsReducer from '../reducers/qrs'
import qrsbaqueiraReducer from '../reducers/qrsbaqueira'
import openingsReducer from '../reducers/openings'

import { AUTH_LOGOUT } from '../actions/auth'

export const history = createHistory()

const myRouterMiddleware = routerMiddleware(history)
const loggerMiddleware = createLogger()

const appReducer = combineReducers({
  auth: authReducer,
  qrs: qrsReducer,
  qrsbaqueira: qrsbaqueiraReducer,
  users: usersReducer,
  openings: openingsReducer,
  form: formReducer,
  router: routerReducer
}) 

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
};

const middleware = process.env.NODE_ENV === 'production' ?
  applyMiddleware(
    thunkMiddleware,
    apiMiddleware,
    myRouterMiddleware
  ) :
  applyMiddleware(
    thunkMiddleware,
    apiMiddleware,
    myRouterMiddleware,
    loggerMiddleware
  )

export const store = createStore(rootReducer, middleware);
