import React from 'react'
import { withNamespaces } from "react-i18next"

class StepBarBaqueira extends React.Component {

  render() {

    const step = this.props.step;

    const { t, i18n } = this.props

    return (
      <div className="row stepbar">

        <div className="stepparent">
          {step > 1 ? (
            <div className="stepOldBaqueira">1</div>
          ) : (
            <div className={`${step == 1 ? "stepActiveBaqueira" : "stepBaqueira"}`}>1</div>
          )}

          <div className={`${step == 1 ? "steplabelActiveBaqueira" : "steplabelBaqueira"}`}>{t('Zona')}</div>
        </div>

        <div className="steplineBaqueira"></div>

        <div className="stepparent">
          {step > 2 ? (
            <div className="stepOldBaqueira">2</div>
          ) : (
            <div className={`${step == 2 ? "stepActiveBaqueira" : "stepBaqueira"}`}>2</div>
          )}

          <div className={`${step == 2 ? "steplabelActiveBaqueira" : "steplabelBaqueira"}`}>{t('Taquilla')}</div>
        </div>

        <div className="steplineBaqueira"></div>

        <div className="stepparent">
          {step > 3 ? (
            <div className="stepOldBaqueira">3</div>
          ) : (
            <div className={`${step == 3 ? "stepActiveBaqueira" : "stepBaqueira"}`}>3</div>
          )}
          
          <div className={`${step == 3 ? "steplabelActiveBaqueira" : "steplabelBaqueira"}`}>{t('Pago')}</div>
        </div>

        <div className="steplineBaqueira"></div>

        <div className="stepparent">
          <div className={`${step == 4 ? "stepActiveBaqueira" : "stepBaqueira"}`}>4</div>
          <div className={`${step == 4 ? "steplabelActiveBaqueira" : "steplabelBaqueira"}`}>{t('Email')}</div>
        </div>

      </div>
    )
  }
}

export default withNamespaces()(StepBarBaqueira)

