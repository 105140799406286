import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'

import { authLogout } from '../actions/auth'
import {withNamespaces} from "react-i18next";

class PaymentError extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  logout() {
    this.props.authLogout()
    this.props.push('/login')
  }

  goTo(number, e) {
    history.push(`/HomeView`)
  }

  componentDidMount() {

  }

  render() {
    const { t, i18n } = this.props
    return (
      <div>
        <AppHeader />
        <div className="lockerContainer">
          <br></br>
          <div>
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('¡TODAS LAS TAQUILLAS ESTÁN OCUPADAS!')}</span>
            </h3>
            <hr className="bg-vw-light" />
            <div className="col-xs-12">
              <img src="/images/incidences.svg" alt="Error!" className="lockerAccessibilityImg" />
            </div>
            <br></br>
            <div className="lockerTicket">
              <h4 className="lockTitle">
                <span className="text-vw-dark">{t('No se ha podido realizar la compra. Vuelve a intentarlo más tarde.')}</span>
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col lockerErrorButtonBig" onClick={e => this.goTo(1, e)}>
            <h1>{t('VOLVER ')}<br></br>{t(' AL INICIO')}</h1>
          </div>
        </div>
      </div>

    )
  }
}

const mapStateToProps = state => ({
  auth: true
})

const mapDispatchToProps = dispatch => ({
  authLogout: bindActionCreators(authLogout, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PaymentError));