import {
  OPENINGS_REQUEST, OPENINGS_SUCCESS, OPENINGS_FAILURE,
  OPENING_REQUEST, OPENING_SUCCESS, OPENING_FAILURE,
  OPENING_UPDATE_REQUEST, OPENING_UPDATE_SUCCESS, OPENING_UPDATE_FAILURE
} from '../actions/openings'

const INITIAL_STATE = {
  openingUpdate: null, opening: null, openings: null, error: null, loading: false
}

const openingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPENINGS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case OPENINGS_SUCCESS:
      return {
        ...state,
        openings: action.response, error: null, loading: false
      }
    case OPENINGS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case OPENING_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case OPENING_SUCCESS:
      return {
        ...state,
        opening: action.response, error: null, loading: false
      }
    case OPENING_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case OPENING_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case OPENING_UPDATE_SUCCESS:
      return {
        ...state,
        openingUpdate: action.response, error: null, loading: false
      }
    case OPENING_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default openingsReducer


