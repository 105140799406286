import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppHeader from '../containers/AppHeader'
import { withNamespaces } from "react-i18next";
import { postQrsale, postBooking } from '../actions/qrs'
import StepBar from '../components/StepBar'

class PhoneView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      phoneNumber: '',
      mail: '',
      parque: 'AQUALANDIA',
      zona: '1',
      accesible: 'NO',
      reserva: 'TODO EL DIA',
      confirmReservation: false,
      newsletterAcceptance: false,
      qrsale: null,
      booking: null,
      lockerSelected: null,
      lockerPrice: null,
      descriptionZone: null,
      selectedAccessibility: null,
      zoneSelected: null,
      selectedLocker: null,
      lockerAssigned: null,
      lockerPrice: null,
      paymentId: null,
      qrToken: null,
      lockerPin: null,
      lockerMatricula: null,
      lockerCustomer: null,
      lockerInvoice: null,
      qrType: "pay",
      qrMode: 0
    }

    this.toggle = this.toggle.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handlePhoneNumberChange(event) {
    const phoneNumber = event.target.value;
    this.setState({ phoneNumber });
  }

  handleMailChange(event) {
    const mail = event.target.value;
    const isValidEmail = this.validateEmail(mail);
    this.setState({ mail, isValidEmail });
  }

  validateEmail(email) {
    // A simple email validation pattern using regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  goTo(e) {

    if (!this.isContinueDisabled()) {
      this.props.push('/ticketView', {
        phoneNumber: this.state.phoneNumber,
        mail: this.state.mail,
        newsletterAcceptance: this.state.newsletterAcceptance,
        confirmReservation: this.state.confirmReservation,
        parque: this.state.parque,
        reserva: this.state.reserva,
        lockerSelected: this.state.lockerSelected,
        lockerPrice: this.state.lockerPrice,
        descriptionZone: this.state.descriptionZone,
        selectedAccessibility: this.state.selectedAccessibility,
        zoneSelected: this.state.zoneSelected,
        selectedLocker: this.state.selectedLocker,
        lockerAssigned: this.state.lockerAssigned,
        lockerPrice: this.state.lockerPrice,
        paymentId: this.state.paymentId,
        qrToken: this.state.qrToken,
        lockerPin: this.state.lockerPin,
        lockerMatricula: this.state.lockerMatricula,
        lockerCustomer: this.state.lockerCustomer,
        lockerInvoice: this.state.lockerInvoice,
        deliverMail: true,
        qrType: this.state.qrType,
        qrMode: this.state.qrMode,
      });
    }
  }

  componentDidMount() {

    let autorizeBooking = true;

    const navigationEntries = window.performance.getEntriesByType('navigation');
    if ((navigationEntries.length > 0) && (navigationEntries[0].type === 'reload')) {
      console.log("Page was reloaded");
      autorizeBooking = false;
    }

    if (autorizeBooking) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const descriptionZone = searchParams.get('descriptionZone');
      const selectedAccessibility = searchParams.get('selectedAccessibility');
      const zoneSelected = searchParams.get('zoneSelected');
      const selectedLocker = searchParams.get('selectedLocker');
      const lockerAssigned = searchParams.get('lockerAssigned');
      const lockerPrice = searchParams.get('lockerPrice');
      const paymentId = searchParams.get('paymentId');
      const qrToken = searchParams.get('qrToken');
      const lockerPin = searchParams.get('lockerPin');
      const lockerMatricula = searchParams.get('lockerMatricula');
      const lockerCustomer = searchParams.get('lockerCustomer');
      const qrType = searchParams.get('qrType');
      const language = searchParams.get('language');
      const qrMode = searchParams.get('qrMode');

      this.props.i18n.changeLanguage(language);

      let salePetition = {}

      const currentDate = new Date();

      // Get the individual components of the date and time
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const hours = String(currentDate.getHours()).padStart(2, '0');
      const minutes = String(currentDate.getMinutes()).padStart(2, '0');
      const seconds = String(currentDate.getSeconds()).padStart(2, '0');

      // Create the formatted timestamp
      const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

      salePetition.TimeStamp = timestamp
      salePetition.Amount = parseInt(lockerPrice)
      salePetition.Code = qrToken
      salePetition.PaymentMethod = "QR"
      salePetition.WorkerSession = "000000"
      salePetition.Language = "ES"
      salePetition.RedsysCode = paymentId
      salePetition.Locker = lockerAssigned

      this.props.postQrsale(salePetition)
        .then((answer) => {
          let answerSale = answer.response.toSaveSale
          let answerSaleCode = answer.response.toSaveSale.Code

          let bookingPetition = {}

          bookingPetition.LockerAssigned = lockerAssigned
          bookingPetition.Phone = "000000000"
          bookingPetition.Origin = "RQ"

          this.props.postBooking(bookingPetition)
            .then((answer) => {
              let answerBooking = answer.response

              this.setState({
                qrsale: answerSale,
                booking: answerBooking,
                descriptionZone: descriptionZone,
                selectedAccessibility: selectedAccessibility,
                zoneSelected: zoneSelected,
                selectedLocker: selectedLocker,
                lockerAssigned: lockerAssigned,
                lockerPrice: lockerPrice,
                paymentId: paymentId,
                qrToken: qrToken,
                lockerPin: lockerPin,
                lockerMatricula: lockerMatricula,
                lockerCustomer: lockerCustomer,
                qrType: qrType,
                lockerInvoice: answerSaleCode,
                qrMode: qrMode
              });

            })
            .catch((error) => {
              console.error(error);
            });

        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  isContinueDisabled() {

    const disabled = ((this.state.phoneNumber.length < 6) || !this.state.isValidEmail || !this.state.confirmReservation)

    return disabled;
  }

  render() {
    const { t, i18n } = this.props;
    const { qrsale, booking } = this.state;

    if (!qrsale || !booking) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    } else {
      return (
        <div>
          <AppHeader />
          <div className="lockerContainer">
            <br></br>
            <StepBar step={4} />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-light">{t('¡TU TAQUILLA HA SIDO RESERVADA!')}</span>
            </h3>
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark">{t('FACILITANOS TUS DATOS DE RESPALDO')}</span>
            </h3>
            <div className="lockTitle">
              <span className="text-vw-dark">{t('Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.')}</span>
            </div>
            <div>
              <br></br>
              <h3 className="lockTitle">
                <span className="text-vw-dark">{t('TELÉFONO*')}</span>
              </h3>
              <input className="phoneInput"
                name="lockerPhone"
                type="tel"
                pattern="[0-9]*"
                value={this.state.phoneNumber}
                onChange={this.handlePhoneNumberChange}
              />
              <br></br>
              <h3 className="lockTitle">
                <span className="text-vw-dark">{t('EMAIL*')}</span>
              </h3>
              <input className="phoneInput"
                name="lockerEmail"
                type="text"
                value={this.state.mail}
                onChange={this.handleMailChange}
                autoCapitalize='none'
              />
              <br></br>
              <div className='divCheckbox'>
                <input
                  type="checkbox"
                  id="confirmReservation"
                  name="confirmReservation"
                  checked={this.state.confirmReservation}
                  onChange={() => this.setState({ confirmReservation: !this.state.confirmReservation })}
                />
                <div className="slider"></div>
                <label htmlFor="confirmReservation" className='textcheckbox slide-checkbox'>
                  {t('Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.')}
                </label>
              </div>
              <div className='divCheckbox'>
                <input
                  type="checkbox"
                  id="newsletterAcceptance"
                  name="newsletterAcceptance"
                  checked={this.state.newsletterAcceptance}
                  onChange={() => this.setState({ newsletterAcceptance: !this.state.newsletterAcceptance })}
                />
                <div className="slider"></div>
                <label htmlFor="newsletterAcceptance" className='textcheckbox slide-checkbox'>
                  {t('Acepto suscribirme al boletín informativo de VACWAY para recibir contenido y notificaciones comerciales.')}

                </label>
              </div>
            </div>
            <br></br>
            <div className="row">
              <div
                className={`col lockerPayButton ${this.isContinueDisabled() ? "disabled" : ""}`}
                onClick={(e) => this.goTo(1, e)}
              >
                <h1>{t("CONTINUAR")}</h1>
              </div>
            </div>
            <br></br>
            <div className="lockTitle">
              <span className="text-vw-dark">{t('*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.')}</span>
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  auth: true,
  qrsale: state.qrsale,
  booking: state.booking
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  postQrsale: bindActionCreators(postQrsale, dispatch),
  postBooking: bindActionCreators(postBooking, dispatch)
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PhoneView));
