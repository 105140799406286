import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import AppBaqueiraHeader from '../containers/AppBaqueiraHeader'
import { withNamespaces } from "react-i18next";
import { postSale } from '../actions/qrsbaqueira'
import StepBarBaqueira from '../components/StepBarBaqueira'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

class PhoneBaqueiraView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      phoneNumber: '',
      mail: '',
      parque: 'AQUALANDIA',
      zona: '1',
      accesible: 'NO',
      reserva: 'TODO EL DIA',
      confirmReservation: false,
      newsletterAcceptance: false,
      qrsale: null,
      booking: null,
      lockerSelected: null,
      lockerPrice: null,
      descriptionZone: null,
      selectedAccessibility: null,
      zoneSelected: null,
      selectedLocker: null,
      lockerAssigned: null,
      lockerPrice: null,
      paymentId: null,
      qrToken: null,
      lockerPin: null,
      lockerMatricula: null,
      lockerCustomer: null,
      lockerInvoice: null,
      qrType: "pay",
      qrMode: 0,
      customerName: '',
      language: null,
      startDate: null,
      endDate: null,
    }

    this.toggle = this.toggle.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.handleMailChange = this.handleMailChange.bind(this);
    this.handleCustomerNameChange = this.handleCustomerNameChange.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handlePhoneNumberChange(value) {
    const phoneNumber = value;
    this.setState({ phoneNumber });
  }

  handleCustomerNameChange(event) {
    const customerName = event.target.value;
    this.setState({ customerName });
  }

  handleMailChange(event) {
    const mail = event.target.value;
    const isValidEmail = this.validateEmail(mail);
    this.setState({ mail, isValidEmail });
  }

  validateEmail(email) {
    // A simple email validation pattern using regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  goTo(e) {

    if (!this.isContinueDisabled()) {
      const saleData = {
        PrerentCode: this.state.lockerInvoice,
        CustomerName: this.state.customerName,
        CustomerEmail: this.state.mail,
        CustomerPhone: this.state.phoneNumber,
        EmailLanguage: this.state.language === 'en' ? 'gb' : 'es',
        SendEmail: true,
      };

      this.props.postSale(saleData)
        .then((answer) => {
          this.props.push('/ticketBaqueiraView', {
            customerName: this.state.customerName,
            phoneNumber: this.state.phoneNumber,
            mail: this.state.mail,
            newsletterAcceptance: this.state.newsletterAcceptance,
            confirmReservation: this.state.confirmReservation,
            parque: this.state.parque,
            reserva: this.state.reserva,
            lockerSelected: this.state.lockerSelected,
            lockerPrice: this.state.lockerPrice,
            descriptionZone: this.state.descriptionZone,
            selectedAccessibility: this.state.selectedAccessibility,
            zoneSelected: this.state.zoneSelected,
            selectedLocker: this.state.selectedLocker,
            lockerAssigned: this.state.lockerAssigned,
            lockerPrice: this.state.lockerPrice,
            paymentId: this.state.paymentId,
            qrToken: this.state.qrToken,
            lockerPin: this.state.lockerPin,
            lockerMatricula: this.state.lockerMatricula,
            lockerCustomer: this.state.lockerCustomer,
            lockerInvoice: this.state.lockerInvoice,
            deliverMail: true,
            qrType: this.state.qrType,
            qrMode: this.state.qrMode,
            language: this.state.language,
            startDate: this.state.startDate,
            endDate: this.state.endDate
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  componentDidMount() {

    let autorizeBooking = true;

    const navigationEntries = window.performance.getEntriesByType('navigation');
    if ((navigationEntries.length > 0) && (navigationEntries[0].type === 'reload')) {
      console.log("Page was reloaded");
      autorizeBooking = false;
    }

    if (autorizeBooking) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const descriptionZone = searchParams.get('descriptionZone');
      const selectedAccessibility = searchParams.get('selectedAccessibility');
      const zoneSelected = searchParams.get('zoneSelected');
      const selectedLocker = searchParams.get('selectedLocker');
      const lockerAssigned = searchParams.get('lockerAssigned');
      const lockerPrice = searchParams.get('lockerPrice');
      const paymentId = searchParams.get('paymentId');
      const qrToken = searchParams.get('qrToken');
      const lockerPin = searchParams.get('lockerPin');
      const lockerMatricula = searchParams.get('lockerMatricula');
      const lockerCustomer = searchParams.get('lockerCustomer');
      const qrType = searchParams.get('qrType');
      const language = searchParams.get('language');
      const qrMode = searchParams.get('qrMode');
      const prerentCode = searchParams.get('prerentCode');
      const startDate = searchParams.get('startDate');
      const endDate = searchParams.get('endDate');

      this.props.i18n.changeLanguage(language);

      this.setState({
        descriptionZone: descriptionZone,
        selectedAccessibility: selectedAccessibility,
        zoneSelected: zoneSelected,
        selectedLocker: selectedLocker,
        lockerAssigned: lockerAssigned,
        lockerPrice: lockerPrice,
        paymentId: paymentId,
        qrToken: qrToken,
        lockerPin: lockerPin,
        lockerMatricula: lockerMatricula,
        lockerCustomer: lockerCustomer,
        qrType: qrType,
        qrMode: qrMode,
        lockerInvoice: prerentCode,
        language: language,
        startDate: startDate,
        endDate: endDate
      });
    }else{
      const qrToken = searchParams.get('qrToken');
      history.push(`/qrsale/${qrToken}`)
    }
  }

  isContinueDisabled() {

    const disabled = ((this.state.phoneNumber.length < 6) || !this.state.isValidEmail || !this.state.confirmReservation || this.state.customerName.length == 0);

    return disabled;
  }

  render() {
    const { t, i18n } = this.props;

    return (
      <div>
        <AppBaqueiraHeader />
        <div className="lockerContainer">
          <br></br>
          <StepBarBaqueira step={4} />
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-light-baqueira">{t('¡TU TAQUILLA HA SIDO RESERVADA!')}</span>
          </h3>
          <br></br>
          <h3 className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('FACILITANOS TUS DATOS DE RESPALDO')}</span>
          </h3>
          <div className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('Tu teléfono se vinculará a la taquilla reservada para poder recuperar el Pin de esta en caso de que lo pierdas. Al email te llegará la confirmación de tu reserva.')}</span>
          </div>
          <div>
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('NOMBRE*')}</span>
            </h3>
            <input className="phoneInput"
              name="name"
              type="text"
              value={this.state.customerName}
              onChange={this.handleCustomerNameChange}
            />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('TELÉFONO*')}</span>
            </h3>
            <PhoneInput
              country="es" // default country code
              value={this.state.phoneNumber}
              onChange={this.handlePhoneNumberChange}
              inputClass="phoneInput" // style to match form
              pattern="[0-9]*"
            />
            <br></br>
            <h3 className="lockTitle">
              <span className="text-vw-dark-baqueira">{t('EMAIL*')}</span>
            </h3>
            <input className="phoneInput"
              name="lockerEmail"
              type="text"
              value={this.state.mail}
              onChange={this.handleMailChange}
              autoCapitalize='none'
            />
            <br></br>
            <div className='divCheckbox'>
              <input
                type="checkbox"
                id="confirmReservation"
                name="confirmReservation"
                checked={this.state.confirmReservation}
                onChange={() => this.setState({ confirmReservation: !this.state.confirmReservation })}
              />
              <div className="slider-baqueira"></div>
              <label htmlFor="confirmReservation" className='textcheckbox-baqueira slide-checkbox'>
                {t('Acepto ceder mi correo electrónico para recibir la confirmación de mi reserva.')}
              </label>
            </div>
            <div className='divCheckbox'>
              <input
                type="checkbox"
                id="newsletterAcceptance"
                name="newsletterAcceptance"
                checked={this.state.newsletterAcceptance}
                onChange={() => this.setState({ newsletterAcceptance: !this.state.newsletterAcceptance })}
              />
              <div className="slider-baqueira"></div>
              <label htmlFor="newsletterAcceptance" className='textcheckbox-baqueira slide-checkbox'>
                {t('Acepto suscribirme al boletín informativo de Baqueira Beret para recibir contenido y notificaciones comerciales.')}

              </label>
            </div>
          </div>
          <br></br>
          <div className="row">
            <div
              className={`col lockerContinueButtonBaqueira ${this.isContinueDisabled() ? "disabled" : ""}`}
              onClick={(e) => this.goTo(1, e)}
            >
              <h1>{t("CONTINUAR")}</h1>
            </div>
          </div>
          <br></br>
          <div className="lockTitle">
            <span className="text-vw-dark-baqueira">{t('*Los datos personales recabados no se usarán en ningún caso para ofrecer comunicados comerciales de ningún tipo si el cliente no se suscribe a la newsletter.')}</span>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: true,
  salebaqueira: state.salebaqueira,
  booking: state.booking
})

const mapDispatchToProps = dispatch => ({
  push: bindActionCreators(push, dispatch),
  postSale: bindActionCreators(postSale, dispatch),
})

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(PhoneBaqueiraView));
